import { default as _91slug_93Po03rnzIFtMeta } from "/vercel/path0/pages/blog/[slug].vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as learn_45mandarinLKNITOSNj5Meta } from "/vercel/path0/pages/learn-mandarin.vue?macro=true";
import { default as portfolioek2jrdhiX7Meta } from "/vercel/path0/pages/portfolio.vue?macro=true";
export default [
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/vercel/path0/pages/blog/[slug].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "learn-mandarin",
    path: "/learn-mandarin",
    component: () => import("/vercel/path0/pages/learn-mandarin.vue")
  },
  {
    name: "portfolio",
    path: "/portfolio",
    component: () => import("/vercel/path0/pages/portfolio.vue")
  }
]